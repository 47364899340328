<template>
  <!-- Main content -->
  <section class="content">
    <!-- 搜索 -->
    <div class="btn-box">
      <el-form :inline="true">
        <el-form-item label="商品id：">
            <el-input v-model="search_id" placeholder="请输入商品id"></el-input>
        </el-form-item>
        <el-form-item label="状态：">
          <el-select v-model="search_status" placeholder="">
            <el-option label="全部" value=""></el-option>
            <el-option label="上架" value="1"></el-option>
            <el-option label="下架" value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="分类标签">
          <el-select v-model="search_category" placeholder="">
            <el-option v-for="item in classifyList" :label="item.name" :value="item.category_id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
            <el-button type="primary" @click="seach(1)">搜索</el-button>
            <el-button type="" @click="reset(1)">重置</el-button>
        </el-form-item>
    </el-form>
    </div>
    <!-- 添加按钮 -->
    <div class="btn-box">
      <el-button type="primary" size="mini" @click="add">添加商品</el-button>
    </div>
    <!-- 表单 -->
    <el-table 
        v-loading="loading"
        :data="actCoummunityList"
        border
        style="width: 100%;">
        <el-table-column fixed label="ID" prop="id" width="50"></el-table-column>
        <el-table-column fixed label="商品标题" prop="item_title" width="250"></el-table-column>
        <el-table-column  label="商品ID" prop="item_id" width="120"></el-table-column>
        <el-table-column label="原价" prop="price"></el-table-column>
        <el-table-column label="到手价" prop="end_price"></el-table-column>
        <el-table-column label="淘口令" prop="kl">
          <template slot-scope="item">
        {{item.row.kl}}
        <el-button type="text" size="small" v-clipboard:copy=item.row.kl v-clipboard:success="copy" >复制</el-button>
    </template>
</el-table-column>
<el-table-column label="邀请人数" prop="invite_num"></el-table-column>
<el-table-column label="权重" prop="weight" width="50"></el-table-column>
<el-table-column label="状态" prop="item_title" width="50">
    <template slot-scope="item">
              {{item.row.status | status}}
            </template>
</el-table-column>
<el-table-column fixed="right" label="操作">
    <template slot-scope="item">
            <el-button type="primary" size="mini" @click="getActCommunityData(item.row.id)">修改</el-button>
            <el-button :type="item.row.status==2?'primary':'danger'" size="mini" @click="changeStatus(item.row.id,item.row.status)"> {{item.row.status==2?'上架':'下架'}} </el-button>
        </template>
</el-table-column>
</el-table>
<!-- 分页 -->
<el-pagination background layout="total, sizes,prev, pager, next,jumper" :total="total" @size-change="sizeChange" @current-change="currentChange" :current-page="page">
</el-pagination>
<!-- 修改添加弹窗 -->
<el-dialog title="添加/修改" :visible.sync="dialog" :close-on-click-modal=false>
    <el-form>
        <el-form-item label="商品id">
            <el-input v-model="item_id" placeholder="" @blur="getGoodsInfo"></el-input>
        </el-form-item>
        <el-form-item label="商品图片">
            <upLoadText @url="imgUrl" id="up" v-model="item_pic"></upLoadText>
        </el-form-item>
        <el-form-item label="商品标题：">
            <el-input v-model="item_title" placeholder=""></el-input>
        </el-form-item>
        <el-form-item label="原价：">
            <el-input v-model="price" placeholder=""></el-input>
        </el-form-item>
        <el-form-item label="到手价">
            <el-input v-model="end_price" placeholder=""></el-input>
        </el-form-item>
        <el-form-item label="淘口令">
            <el-input v-model="kl" placeholder=""></el-input>
        </el-form-item>
        <el-form-item label="分类标签">
            <el-select v-model="category_id" placeholder="">
                <el-option v-for="item in classifyList" :label="item.name" :value="item.category_id"></el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="邀请人数：">
            <el-input v-model="invite_num" placeholder=""></el-input>
        </el-form-item>
        <el-form-item label="权重">
            <el-input v-model="weight" type="number" placeholder=""></el-input>
        </el-form-item>
        <el-form-item label="状态">
            <el-radio-group v-model="status">
                <el-radio :label="1">上架</el-radio>
                <el-radio :label="2">下架</el-radio>
            </el-radio-group>
        </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
        <el-button @click="dialog = false">取 消</el-button>
        <el-button type="primary" @click="alter">确 定</el-button>
    </div>
</el-dialog>
</section>
</template>
<script>
    let common = JSON.parse(localStorage.getItem('common'))
    import * as api from "@/config/api";
    export default {
        name: 'channelClassify',
        data() {
            return {
                loading: false,
                pagesize: 10,
                page: 1,
                total: 0,

                classifyList: common.act_community_gift_category,

                dialog: false,
                actCoummunityList: [],

                search_id: '',
                search_status: '',
                search_category: '',

                id: '',
                item_id: '',
                item_pic: '',
                item_title: '',
                price: '',
                end_price: '',
                kl: '',
                invite_num: '',
                category_id: '',
                weight: '',
                status: '1',
            }
        },
        filters: {
            status(val) {
                switch (Number(val)) {
                    case 1:
                        return '上架'
                        break;
                    case 2:
                        return '下架'
                        break
                    default:
                        break;
                }
            }
        },
        mounted() {
            this.getActCommunityList()
        },
        methods: {
            // 获取列表
            getActCommunityList(page, size) {
                api.getActCommunityList({
                    page: page ? page : this.page,
                    pagesize: size ? size : this.pagesize,
                    item_id: this.search_id,
                    status: this.search_status,
                    category_id: this.search_category

                }, res => {
                    this.total = Number(res.data.count)
                    this.actCoummunityList = res.data.list
                    this.loading = false
                })
            },
            // 分页点击
            sizeChange(val) {
                this.pagesize = val
                this.getActCommunityList()
            },
            currentChange(val) {
                this.page = val
                this.getActCommunityList()
            },
            //搜索
            seach(page) {
                this.page = page
                this.getActCommunityList(page)
            },
            reset(page) {
                this.search_id = ''
                this.search_status = ''
                this.search_category = ''
                this.page = page
                this.getActCommunityList(page)
            },
            // 复制
            copy() {
                this.$message({
                    message: '复制成功',
                    type: 'success'
                })
            },
            // 上传图片
            imgUrl(val) {
                this.item_pic = val
            },
            // 获取详情
            getActCommunityData(id) {
                this.id = id
                api.getActCommunityData({
                    id: id
                }, res => {
                    this.item_id = res.data.item_id
                    this.item_pic = res.data.item_pic
                    this.item_title = res.data.item_title
                    this.price = res.data.price
                    this.end_price = res.data.end_price
                    this.kl = res.data.kl
                    this.invite_num = res.data.invite_num
                    this.category_id = res.data.category_id
                    this.weight = res.data.weight
                    this.status = Number(res.data.status)
                    this.dialog = true
                })
            },
            // 添加选品
            add() {
                this.item_id = ''
                this.item_pic = ''
                this.item_title = ''
                this.price = ''
                this.end_price = ''
                this.kl = ''
                this.invite_num = ''
                this.category_id = ''
                this.weight = ''
                this.status = 1
                this.dialog = true
            },
            // 修改、添加频道分类
            alter() {
                if (this.id != '') {
                    api.updateActCommunity({
                        id: this.id,
                        item_id: this.item_id,
                        item_pic: this.item_pic,
                        item_title: this.item_title,
                        price: this.price,
                        end_price: this.end_price,
                        kl: this.kl,
                        invite_num: this.invite_num,
                        category_id: this.category_id,
                        weight: this.weight,
                        status: this.status,
                    }, res => {
                        this.$message({
                            message: res.msg,
                            type: 'success'
                        })
                        this.dialog = false
                        this.getActCommunityList()
                    })
                    return
                }
                api.addActCommunity({
                    item_id: this.item_id,
                    item_pic: this.item_pic,
                    item_title: this.item_title,
                    price: this.price,
                    end_price: this.end_price,
                    kl: this.kl,
                    invite_num: this.invite_num,
                    category_id: this.category_id,
                    weight: this.weight,
                    status: this.status,
                }, res => {
                    this.$message({
                        message: res.msg,
                        type: 'success'
                    })
                    this.dialog = false
                    this.getActCommunityList()
                })
            },
            // 上下架
            changeStatus(id, status) {
                api.updateActCommunityStatus({
                    id: id,
                    status: status == 1 ? 2 : 1
                }, res => {
                    this.$message({
                        type: 'success',
                        message: res.msg
                    })
                    this.getActCommunityList()
                })
            },
            // 获取信息
            getGoodsInfo() {
                api.getGoodsInfo({
                    item_id: this.item_id,
                    platform: 1
                }, res => {
                    console.log(res)
                    this.item_pic = res.data.item_pic
                    this.item_title = res.data.item_title
                    this.price = res.data.price
                })
            }
        }
    }
</script>
<style lang="scss">
    .btn-box {
        text-align: left;
        margin-bottom: 20px;
    }
    
    .el-image img {
        height: 40px;
    }
    
    .el-image-viewer__canvas img {
        width: auto;
        height: auto;
    }
</style>